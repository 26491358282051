import { gsap } from "gsap";
    
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger,ScrollToPlugin);

import Swiper from 'swiper';
import { Navigation, Pagination, Grid, EffectFade } from 'swiper/modules';


import 'swiper/css/bundle';

import '../css/main.css';




// Using GSAP, hide the ".o-hero-heading" when the section #intro is out of the viewport
gsap.to('.o-hero-heading', {
    scrollTrigger: {
      trigger: '#intro',
      start: 'bottom top',
      scrub: true,
    },
    opacity: 0,
    duration: 0.5,
  });

// Show the ".logo-nav" when the logo-intro is going out of the viewport
gsap.to('.logo-nav', {
    scrollTrigger: {
      trigger: '.logo-intro',
      start: 'top top',
      scrub: true,
    },
    opacity: 1,
    duration: 1,
  });

// Go to Top when clicking the logo
const topButton = document.querySelector('.logo-nav');
// On click or tap, scroll to the top of the page
topButton.addEventListener('click', () => {
  gsap.to(window, { duration: 2, scrollTo: 0 });
});



// Detect if a link's href goes to the current page
function getSamePageAnchor (link) {
    if (
      link.protocol !== window.location.protocol ||
      link.host !== window.location.host ||
      link.pathname !== window.location.pathname ||
      link.search !== window.location.search
    ) {
      return false;
    }
  
    return link.hash;
  }

// Scroll to #section when clicking on .anchor-link
// Scroll to a given hash, preventing the event given if there is one
function scrollToHash(hash, e) {
  const elem = hash ? document.querySelector(hash) : false;
  if(elem) {
    if(e) e.preventDefault();
    gsap.to(window, {scrollTo: elem});
  }
}

// If a link's href is within the current page, scroll to it instead
document.querySelectorAll('a[href]').forEach(a => {
a.addEventListener('click', e => {
    scrollToHash(getSamePageAnchor(a), e);
});
});

// Scroll to the element in the URL's hash on load
scrollToHash(window.location.hash);





// Nav Menu Toggle
// if nav-toggle exists

// Nav setup
const primaryNav = document.querySelector('.primary-nav');
const navToggle = document.querySelector('.nav-toggle');

const navToggleText = document.querySelector('.navigation-text');
// get text from class "navigation-text_close"
const navToggleClose = document.querySelector('.navigation-text_close').innerHTML;
// get text from class "navigation-text_menu"
const navToggleMenu = document.querySelector('.navigation-text_menu').innerHTML;

navToggle.addEventListener('click', () => {
    const visibility = primaryNav.getAttribute('data-visible');
    if (visibility === 'false') {
    primaryNav.setAttribute('data-visible', 'true');
    navToggle.setAttribute('aria-expanded', 'true');
    // Change toggle text to close
    navToggleText.innerHTML = navToggleClose;
    } else if (visibility === 'true') {
    primaryNav.setAttribute('data-visible', 'false');
    navToggle.setAttribute('aria-expanded', 'false');
    // Change toggle text to menu
    navToggleText.innerHTML = navToggleMenu;

    }

});

// When clicking outside of the nav, close it
document.addEventListener('click', (event) => {
    const isClickInside = primaryNav.contains(event.target);
    const isClickToggle = navToggle.contains(event.target);
    const visibility = primaryNav.getAttribute('data-visible');
    if (visibility === 'true' && !isClickInside && !isClickToggle) {
    primaryNav.setAttribute('data-visible', 'false');
    navToggle.setAttribute('aria-expanded', 'false');
    // Change toggle text to close
    navToggleText.innerHTML = navToggleMenu;
    }
});




// When a section with an id is in the viewport, add .active to the corresponding nav link.
let links = gsap.utils.toArray(".c-index a");
links.forEach(a => {
  let href = a.getAttribute("href");
  if (href.startsWith("#")) { // Check if href starts with #
    let element = document.querySelector(href),
        linkST = ScrollTrigger.create({
              trigger: element,
              start: "top top"
            });
    ScrollTrigger.create({
      trigger: element,
      start: "top center",
      end: "bottom center",
      onToggle: self => self.isActive && setActive(a)
    });
    a.addEventListener("click", e => {
      e.preventDefault();
      gsap.to(window, {duration: 1, scrollTo: linkST.start, overwrite: "auto"});
    });
  }
});

function setActive(link) {
  links.forEach(el => el.classList.remove("active"));
  link.classList.add("active");
}









// Create swiper only if class exists

// configure Swiper to use modules
new Swiper('.swiper-timeline', {
    modules: [Navigation, EffectFade],
    slidesPerView: 1,
    spaceBetween: 0,
    autoHeight: true,
    centeredSlides: true,
    initialSlide: 2,
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    // Navigation arrows
    navigation: {
        nextEl: '.next-timeline',
        prevEl: '.prev-timeline',
    },
    });

  
// configure Swiper to use modules
new Swiper('.swiper-stories', {
    modules: [Navigation, Pagination, Grid],
    slidesPerView: 1,
    spaceBetween: 10,
    grabCursor: true,
    slidesPerGroup: 1,
    // If we need pagination
    pagination: {
    el: '.pagination-stories',
    clickable: true,

    },
    // Navigation arrows
    navigation: {
    nextEl: '.next-stories',
    prevEl: '.prev-stories',
    },
    grid: {
    rows: 4,
    fill: 'row',
    },
    breakpoints: {
      // when window width is >= 320px
      768: {
        slidesPerView: 2,
        // spaceBetween: 20,
        slidesPerGroup: 2,
        grid: {
          rows: 2,
          },
      }
    }
});

  
  
// configure Swiper to use modules
new Swiper('.swiper-whats-going-on', {
    modules: [Navigation, Pagination, Grid],
    slidesPerView: 1,
    spaceBetween: 10,
    grabCursor: true,
    slidesPerGroup: 1,
    // If we need pagination
    pagination: {
        el: '.pagination-whats-going-on',
        clickable: true,

    },
    // Navigation arrows
    navigation: {
        nextEl: '.next-whats-going-on',
        prevEl: '.prev-whats-going-on',
    },
    grid: {
        rows: 4,
        fill: 'row',
    },
    breakpoints: {
      // when window width is >= 320px
      768: {
        slidesPerView: 2,
        // spaceBetween: 20,
        slidesPerGroup: 2,
        grid: {
          rows: 2,
          },
      }
    }
    });
  